module.exports = require("deepmerge")(require("./config.default.js"), {
  native: {
    appleTeamId: "2BAP3P29V2",
    name: {
      default: "Texworld Evolution Paris_D"
    },
    id: "com.messefrankfurt.Texworld.dev",
    playStoreUrl: ""
  },
  web: {
    url: "texworld0722.ms-dev.mobile-spot.com"
  },
  relatedRepos: [{
    src: "git@bitbucket.org:mobilespotdev/cordova.git",
    branch: "inte/texworld-0722/main",
    postCommands: ["cd cordova && npm install"]
  }, {
    src: "git@bitbucket.org:mobilespotdev/mobigeo.git",
    branch: "inte/texworld-0722/main",
    postCommands: ["cd mobigeo && npm install"]
  }, {
    src: "git@bitbucket.org:mobilespotdev/mobigeo-customs-texworld-0722.git",
    branch: "inte/texworld-0722/main",
    dest: "mobigeo/mobigeo-customs"
  }, {
    src: "git@bitbucket.org:mobilespotdev/app-react.git",
    branch: "inte/texworld-0722/main"
  }, {
    src: "git@bitbucket.org:mobilespotdev/data-texworld-0722.git",
    branch: "inte/texworld-0722/main",
    dest: "app-react/data",
    postCommands: ["cd app-react && yarn", "cd app-react && npm run set-env dev", "cd app-react && npm run download-assets", "cd app-react && npm run download-data-files", "cd app-react && npm run update-mobigeo"]
  }]
});