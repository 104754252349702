import _defineProperty from "/Users/mohamedbenkhedher/Documents/react/mspot-texworld-0722/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { // DATA_TYPE_ANIMATIONS,
DATA_TYPE_EVENTS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_SPEAKERS, // DATA_TYPE_PAPERS,
DATA_TYPE_EXHIBITORS, DATA_TYPE_EXHIBITOR_CATEGORIES, // DATA_TYPE_PAPER_CATEGORIES,
// DATA_TYPE_AIRCRAFTS,
// DATA_TYPE_AIRCRAFT_CATEGORIES,
DATA_TYPE_NEWPRODUCTS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, // DATA_TYPE_DOCUNITS,
DATA_TYPE_PARTICIPANTS, DATA_TYPE_CONTACTED_PARTICIPANTS, DATA_TYPE_CONTACTS, DATA_TYPE_KLIPSO_LEADS_CONTACTS, getSpeakerTitle, DATA_TYPE_GMAP_PLACES, DATA_TYPE_STORES, DATA_TYPE_STORE_CATEGORIES, DATA_TYPE_BUILDINGS, DATA_TYPE_FLOORS, DATA_TYPE_GOLDENSUMMIT, DATA_TYPE_GOLDENSUMMIT_CATEGORIES, DATA_TYPE_USERPROJECTS, DATA_TYPE_USERPROJECT_CATEGORIES, DATA_TYPE_JOBOFFERS, DATA_TYPE_JOBOFFER_CATEGORIES, DATA_TYPE_CONTESTS, DATA_TYPE_CONTEST_CATEGORIES, DATA_TYPE_MEETING_CONTACTS } from 'data/config/dataConfig';
import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';
import { SEARCH_TYPES } from 'src/core/search/Search';

var _require = require('./profiles'),
    DEFAULT_PROFILE = _require.DEFAULT_PROFILE,
    WEBAPP_EXH_PROFILE = _require.WEBAPP_EXH_PROFILE,
    WEBAPP_EVT_PROFILE = _require.WEBAPP_EVT_PROFILE; // Perform search when user has typed at least n characters


export var SEARCH_MIN_CHARS = 2; // export const SEARCH_HIDE_LIST_IF_TOTAL_ABOVE = 10;

/**
 * Data types to look through to find POI on map or itinerary start/destination
 * @type {Array}
 */

export var DATATYPES_WITH_PLACES = [// DATA_TYPE_AIRCRAFTS,
// DATA_TYPE_ANIMATIONS,
DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_STORES // DATA_TYPE_SERVICES
];
export function getSearchType(pageKey, value) {
  if (pageKey !== SEARCH_PAGE_KEY) {
    if (value.length === 1) {
      return SEARCH_TYPES.STARTS_WITH;
    }

    if (value.length === 2) {
      return SEARCH_TYPES.WORD_STARTS_WITH;
    }
  }
} // Common 'title'

var getTitleAttribute = function getTitleAttribute(item) {
  return {
    text: item.title
  };
};

var getExhibitorAttributes = function getExhibitorAttributes(item) {
  var fields = {
    text: item.title
  };

  if (item.description) {
    fields.keywords = [item.description];
  }

  if (item.lump.catTag && item.lump.catTag.length > 0) {
    fields.keywords = fields.keywords ? fields.keywords.concat(item.lump.catTag) : item.lump.catTag;
  }

  return fields;
};

var getPaperAttributes = function getPaperAttributes(item) {
  var fields = {
    text: item.title
  };

  if (item.lump.authors) {
    fields.text += " ".concat(item.lump.authors);
  }

  return fields;
};

var getSpeakerAttributes = function getSpeakerAttributes(item) {
  var fields = {
    text: getSpeakerTitle(item)
  };

  if (item.organisation) {
    fields.text += " ".concat(item.organisation);
  }

  return fields;
};

var getEventAttributes = function getEventAttributes(item) {
  var fields = {
    text: item.title
  };

  if (item.lump && item.lump.keywords) {
    fields.text += " ".concat(item.lump.keywords);
  }

  return fields;
};
/**
 * @return {Object} config where:
 *   -key: datatype to include in search scope
 *   -value: function returning the string on which the search is applied
 */


export function get(profile, pageKey) {
  var _config, _config2, _config3;

  var config = '';

  switch (profile) {
    case DEFAULT_PROFILE:
      // Search for DEFAULT_PROFILE
      config = (_config = {}, _defineProperty(_config, DATA_TYPE_EXHIBITORS, getExhibitorAttributes), _defineProperty(_config, DATA_TYPE_EXHIBITOR_CATEGORIES, getTitleAttribute), _defineProperty(_config, DATA_TYPE_EVENTS, getEventAttributes), _defineProperty(_config, DATA_TYPE_EVENT_CATEGORIES, getTitleAttribute), _defineProperty(_config, DATA_TYPE_SPEAKERS, getSpeakerAttributes), _config);
      break;
    // Search for WEBAPP_EXH_PROFILE

    case WEBAPP_EXH_PROFILE:
      config = (_config2 = {}, _defineProperty(_config2, DATA_TYPE_EXHIBITORS, getExhibitorAttributes), _defineProperty(_config2, DATA_TYPE_EXHIBITOR_CATEGORIES, getTitleAttribute), _defineProperty(_config2, DATA_TYPE_NEWPRODUCTS, getTitleAttribute), _defineProperty(_config2, DATA_TYPE_NEWPRODUCT_CATEGORIES, getTitleAttribute), _config2);
      break;
    // Search  for WEBAPP_EVT_PROFILE

    case WEBAPP_EVT_PROFILE:
      config = (_config3 = {}, _defineProperty(_config3, DATA_TYPE_NEWPRODUCT_CATEGORIES, getTitleAttribute), _defineProperty(_config3, DATA_TYPE_EVENTS, getEventAttributes), _defineProperty(_config3, DATA_TYPE_EVENT_CATEGORIES, getTitleAttribute), _defineProperty(_config3, DATA_TYPE_SPEAKERS, getSpeakerAttributes), _config3);
      break;
  }

  if (pageKey !== SEARCH_PAGE_KEY) {
    // These data type are in the scope of contextual searches only:
    config[DATA_TYPE_PARTICIPANTS] = function (item) {
      return "".concat(item.lastName || '', " ").concat(item.role || '', " ").concat(item.organizationName || '', " ").concat(item.type || '');
    };

    config[DATA_TYPE_CONTACTED_PARTICIPANTS] = function (item) {
      return "".concat(item.lastName || '', " ").concat(item.role || '', " ").concat(item.organizationName || '', " ").concat(item.type || '');
    };

    config[DATA_TYPE_CONTACTS] = function (item) {
      return "".concat(item.firstName || '', " ").concat(item.lastName || '', " ").concat(item.role || '', " ").concat(item.organizationName || '');
    };

    config[DATA_TYPE_KLIPSO_LEADS_CONTACTS] = function (item) {
      return "".concat(item.Prenom || '', " ").concat(item.Nom || '', " ").concat(item.Societe || '', " ").concat(item.Email || '');
    };
  } // if (profile === 'presse') {
  //     config[ DATA_TYPE_DOCUNITS ] = getTitleAttribute
  // }


  return config;
}