module.exports = {
  name: "texworld-0722",
  client: "MESSE FRANKFURT FRANCE",
  description: "Mobile app for TEXWORLD",
  pluginId: "mobigeo-plugin-texworld",
  validLangs: ["fr", "en"],
  version: {
    mainVersion: "6.0.2",
    buildVersion: {
      iOS: "69",
      android: "68",
      web: "69"
    }
  },
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    iosBuildTarget: "11.0",
    androidFadeSplashScreenDuration: 750,
    androidTargetSdkVersion: 30,
    androidSigning: {
      keyAlias: "mobilespot-textWorld",
      keyStorePassword: "jee1Uu0Hieloh7AZ"
    },
    app: "app-react"
  },
  undeliveredFolders: ["source", "exports"],
  crypto: false,
  web: {}
};